//**********************************************
// Variables
//**********************************************

// Paths
$media-url:       "../media";
$font-url:        "../fonts";

// Layout
$container-width: 680px;
$header-height:   4rem;
$footer-height:   5rem;

//**********************************************
// Bootstrap Variables
//**********************************************

@import "../../node_modules/bootstrap/scss/variables";

// Prefix

$prefix:                                      bs-;

// Options

$enable-caret:                                true;
$enable-rounded:                              true;
$enable-shadows:                              false;
$enable-gradients:                            false;
$enable-transitions:                          true;
$enable-reduced-motion:                       true;
$enable-smooth-scroll:                        true;
$enable-grid-classes:                         true;
$enable-container-classes:                    true;
$enable-cssgrid:                              false;
$enable-button-pointers:                      true;
$enable-rfs:                                  true;
$enable-validation-icons:                     false; // *
$enable-negative-margins:                     false;
$enable-deprecation-messages:                 true;
$enable-important-utilities:                  true;
$enable-dark-mode:                            true;
$color-mode-type:                             data; // `data` or `media-query`

// Color

$blue:                                        #2f56ff; // *
$indigo:                                      #6610f2;
$purple:                                      #6f42c1;
$pink:                                        #d63384;
$red:                                         #e22626; // *
$orange:                                      #fd7e14;
$yellow:                                      #ffc107;
$green:                                       #198754;
$teal:                                        #20c997;
$cyan:                                        #20bbf7; // *

$primary:                                     #2f56ff; // * $blue
$secondary:                                   $cyan; // *
$success:                                     $green;
$info:                                        $cyan;
$warning:                                     $yellow;
$danger:                                      $red;
$light:                                       $gray-100;
$dark:                                        $gray-900;

$min-contrast-ratio:                          4.5;

// Gradient

$gradient:                                    linear-gradient(180deg, rgba($white, .15), rgba($white, 0));

// Spacing

$spacer:                                      1rem;
$spacers:                                     (
                                                  0: 0,
                                                  1: $spacer * .25,
                                                  2: $spacer * .5,
                                                  3: $spacer,
                                                  4: $spacer * 1.5,
                                                  5: $spacer * 3,
                                              );

// Body

$body-text-align:                             null;
$body-color:                                  $gray-900;
$body-bg:                                     $white;
$body-secondary-color:                        rgba($body-color, .75);
$body-secondary-bg:                           $gray-200;
$body-tertiary-color:                         rgba($body-color, .5);
$body-tertiary-bg:                            $gray-100;
$body-emphasis-color:                         $black;

// Navigation

$link-color:                                  $primary;
$link-decoration:                             underline;
$link-shade-percentage:                       20%;
$link-hover-color:                            shift-color($link-color, $link-shade-percentage);
$link-hover-decoration:                       null;
$stretched-link-pseudo-element:               after;
$stretched-link-z-index:                      1;

// Icon links
$icon-link-gap:                               .375rem;
$icon-link-underline-offset:                  .25em;
$icon-link-icon-size:                         1em;
$icon-link-icon-transition:                   .2s ease-in-out transform;
$icon-link-icon-transform:                    translate3d(.25em, 0, 0);

// Paragraphs

$paragraph-margin-bottom:                     1rem;

// Grid breakpoints

$grid-breakpoints:                            (
                                                  xs: 0,
                                                  sm: 576px,
                                                  md: 768px,
                                                  lg: 992px,
                                                  xl: 1200px,
                                                  xxl: 1400px
                                              );

///@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
///@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid containers

$container-max-widths:                        (
                                                  sm: 540px,
                                                  md: 720px,
                                                  lg: 960px,
                                                  xl: 1140px,
                                                  xxl: 1320px
                                              );

///@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns

$grid-columns:                                12;
$grid-gutter-width:                           1.5rem;
$grid-row-columns:                            6;
$container-padding-x:                         $grid-gutter-width;

// Components

$border-width:                                1px;
$border-widths:                               (
                                                  1: 1px,
                                                  2: 2px,
                                                  3: 3px,
                                                  4: 4px,
                                                  5: 5px
                                              );
$border-style:                                solid;
$border-color:                                $gray-300;
$border-color-translucent:                    rgba($black, .175);
$border-radius:                               .375rem;
$border-radius-sm:                            .25rem;
$border-radius-lg:                            .5rem;
$border-radius-xl:                            1rem;
$border-radius-xxl:                           2rem;
$border-radius-pill:                          50rem;
$box-shadow:                                  0 .5rem 1rem rgba($black, .15);
$box-shadow-sm:                               0 .125rem .25rem rgba($black, .075);
$box-shadow-lg:                               0 1rem 3rem rgba($black, .175);
$box-shadow-inset:                            inset 0 1px 2px rgba($black, .075);
$component-active-color:                      $white;
$component-active-bg:                         $primary;
$focus-ring-width:                            .25rem;
$focus-ring-opacity:                          .25;
$focus-ring-color:                            rgba($primary, $focus-ring-opacity);
$focus-ring-blur:                             0;
$focus-ring-box-shadow:                       0 0 $focus-ring-blur $focus-ring-width $focus-ring-color;
$caret-width:                                 .3em;
$caret-vertical-align:                        $caret-width * .85;
$caret-spacing:                               $caret-width * .85;

// Typography

$font-family-sans-serif:                      Inter, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace:                       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:                            var(--#{$prefix}font-sans-serif);
$font-family-code:                            var(--#{$prefix}font-monospace);
$font-size-root:                              null;
$font-size-base:                              1rem; // Assumes the browser default, typically `16px`
$font-size-sm:                                $font-size-base * .875;
$font-size-lg:                                $font-size-base * 1.25;
$font-weight-lighter:                         lighter;
$font-weight-light:                           300;
$font-weight-normal:                          400;
$font-weight-medium:                          500;
$font-weight-semibold:                        600;
$font-weight-bold:                            700;
$font-weight-bolder:                          bolder;
$font-weight-base:                            $font-weight-normal;
$line-height-base:                            1.5;
$line-height-sm:                              1.25;
$line-height-lg:                              2;
$h1-font-size:                                $font-size-base * 2.5;
$h2-font-size:                                $font-size-base * 2;
$h3-font-size:                                $font-size-base * 1.75;
$h4-font-size:                                $font-size-base * 1.5;
$h5-font-size:                                $font-size-base * 1.25;
$h6-font-size:                                $font-size-base;
$headings-margin-bottom:                      $spacer * .5;
$headings-font-family:                        null;
$headings-font-style:                         null;
$headings-font-weight:                        500;
$headings-line-height:                        1.2;
$headings-color:                              inherit;
$display-font-sizes:                          (
                                                  1: 5rem,
                                                  2: 4.5rem,
                                                  3: 4rem,
                                                  4: 3.5rem,
                                                  5: 3rem,
                                                  6: 2.5rem
                                              );
$display-font-family:                         null;
$display-font-style:                          null;
$display-font-weight:                         300;
$display-line-height:                         $headings-line-height;
$lead-font-size:                              $font-size-base * 1.25;
$lead-font-weight:                            300;
$small-font-size:                             .875em;
$sub-sup-font-size:                           .75em;
$initialism-font-size:                        $small-font-size;
$blockquote-margin-y:                         $spacer;
$blockquote-font-size:                        $font-size-base * 1.25;
$blockquote-footer-color:                     $gray-600;
$blockquote-footer-font-size:                 $small-font-size;
$hr-margin-y:                                 $spacer;
$hr-color:                                    inherit;
$hr-border-color:                             null; // Allows for inherited colors
$hr-border-width:                             var(--#{$prefix}border-width);
$hr-opacity:                                  .25;
$vr-border-width:                             var(--#{$prefix}border-width);
$legend-margin-bottom:                        .5rem;
$legend-font-size:                            1.5rem;
$legend-font-weight:                          null;
$dt-font-weight:                              $font-weight-bold;
$list-inline-padding:                         .5rem;
$mark-padding:                                .1875em;
$mark-color:                                  $body-color;
$mark-bg:                                     $yellow-100;

// Tables

$table-cell-padding-y:                        .5rem;
$table-cell-padding-x:                        .5rem;
$table-cell-padding-y-sm:                     .25rem;
$table-cell-padding-x-sm:                     .25rem;
$table-cell-vertical-align:                   top;
$table-color:                                 var(--#{$prefix}emphasis-color);
$table-bg:                                    var(--#{$prefix}body-bg);
$table-accent-bg:                             transparent;
$table-th-font-weight:                        null;
$table-striped-color:                         $table-color;
$table-striped-bg-factor:                     .05;
$table-striped-bg:                            rgba(var(--#{$prefix}emphasis-color-rgb), $table-striped-bg-factor);
$table-active-color:                          $table-color;
$table-active-bg-factor:                      .1;
$table-active-bg:                             rgba(var(--#{$prefix}emphasis-color-rgb), $table-active-bg-factor);
$table-hover-color:                           $table-color;
$table-hover-bg-factor:                       .075;
$table-hover-bg:                              rgba(var(--#{$prefix}emphasis-color-rgb), $table-hover-bg-factor);
$table-border-factor:                         .2;
$table-border-width:                          var(--#{$prefix}border-width);
$table-border-color:                          var(--#{$prefix}border-color);
$table-striped-order:                         odd;
$table-striped-columns-order:                 even;
$table-group-separator-color:                 currentcolor;
$table-caption-color:                         var(--#{$prefix}secondary-color);
$table-bg-scale:                              -80%;

// Buttons + Forms

$input-btn-padding-y:                         .375rem;
$input-btn-padding-x:                         .75rem;
$input-btn-font-family:                       null;
$input-btn-font-size:                         $font-size-base;
$input-btn-line-height:                       $line-height-base;
$input-btn-focus-width:                       $focus-ring-width;
$input-btn-focus-color-opacity:               $focus-ring-opacity;
$input-btn-focus-color:                       $focus-ring-color;
$input-btn-focus-blur:                        $focus-ring-blur;
$input-btn-focus-box-shadow:                  $focus-ring-box-shadow;
$input-btn-padding-y-sm:                      .25rem;
$input-btn-padding-x-sm:                      .5rem;
$input-btn-font-size-sm:                      $font-size-sm;
$input-btn-padding-y-lg:                      .7rem; // *
$input-btn-padding-x-lg:                      1rem;
$input-btn-font-size-lg:                      $font-size-lg;
$input-btn-border-width:                      var(--#{$prefix}border-width);

// Buttons

$btn-color:                                   var(--#{$prefix}body-color);
$btn-padding-y:                               $input-btn-padding-y;
$btn-padding-x:                               $input-btn-padding-x;
$btn-font-family:                             $input-btn-font-family;
$btn-font-size:                               $input-btn-font-size;
$btn-line-height:                             $input-btn-line-height;
$btn-white-space:                             null; // Set to `nowrap` to prevent text wrapping
$btn-padding-y-sm:                            $input-btn-padding-y-sm;
$btn-padding-x-sm:                            $input-btn-padding-x-sm;
$btn-font-size-sm:                            $input-btn-font-size-sm;
$btn-padding-y-lg:                            $input-btn-padding-y-lg;
$btn-padding-x-lg:                            $input-btn-padding-x-lg;
$btn-font-size-lg:                            $input-btn-font-size-lg;
$btn-border-width:                            $input-btn-border-width;
$btn-font-weight:                             $font-weight-normal;
$btn-box-shadow:                              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075);
$btn-focus-width:                             $input-btn-focus-width;
$btn-focus-box-shadow:                        $input-btn-focus-box-shadow;
$btn-disabled-opacity:                        .65;
$btn-active-box-shadow:                       inset 0 3px 5px rgba($black, .125);
$btn-link-color:                              var(--#{$prefix}link-color);
$btn-link-hover-color:                        var(--#{$prefix}link-hover-color);
$btn-link-disabled-color:                     $gray-600;
$btn-link-focus-shadow-rgb:                   to-rgb(mix(color-contrast($link-color), $link-color, 15%));
$btn-border-radius:                           var(--#{$prefix}border-radius);
$btn-border-radius-sm:                        var(--#{$prefix}border-radius-sm);
$btn-border-radius-lg:                        .375rem; // * var(--#{$prefix}border-radius-lg);
$btn-transition:                              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
$btn-hover-bg-shade-amount:                   15%;
$btn-hover-bg-tint-amount:                    15%;
$btn-hover-border-shade-amount:               20%;
$btn-hover-border-tint-amount:                10%;
$btn-active-bg-shade-amount:                  20%;
$btn-active-bg-tint-amount:                   20%;
$btn-active-border-shade-amount:              25%;
$btn-active-border-tint-amount:               10%;

// Forms

$form-text-margin-top:                        .25rem;
$form-text-font-size:                         $small-font-size;
$form-text-font-style:                        null;
$form-text-font-weight:                       null;
$form-text-color:                             var(--#{$prefix}secondary-color);

$form-label-margin-bottom:                    .5rem;
$form-label-font-size:                        null;
$form-label-font-style:                       null;
$form-label-font-weight:                      null;
$form-label-color:                            null;

$input-padding-y:                             $input-btn-padding-y;
$input-padding-x:                             $input-btn-padding-x;
$input-font-family:                           $input-btn-font-family;
$input-font-size:                             $input-btn-font-size;
$input-font-weight:                           $font-weight-base;
$input-line-height:                           $input-btn-line-height;
$input-padding-y-sm:                          $input-btn-padding-y-sm;
$input-padding-x-sm:                          $input-btn-padding-x-sm;
$input-font-size-sm:                          $input-btn-font-size-sm;
$input-padding-y-lg:                          $input-btn-padding-y-lg;
$input-padding-x-lg:                          $input-btn-padding-x-lg;
$input-font-size-lg:                          $input-btn-font-size-lg;
$input-bg:                                    var(--#{$prefix}body-bg);
$input-disabled-color:                        null;
$input-disabled-bg:                           var(--#{$prefix}secondary-bg);
$input-disabled-border-color:                 null;
$input-color:                                 var(--#{$prefix}body-color);
$input-border-color:                          var(--#{$prefix}border-color);
$input-border-width:                          $input-btn-border-width;
$input-box-shadow:                            var(--#{$prefix}box-shadow-inset);
$input-border-radius:                         var(--#{$prefix}border-radius);
$input-border-radius-sm:                      var(--#{$prefix}border-radius-sm);
$input-border-radius-lg:                      var(--#{$prefix}border-radius-lg);
$input-focus-bg:                              $input-bg;
$input-focus-border-color:                    tint-color($component-active-bg, 50%);
$input-focus-color:                           $input-color;
$input-focus-width:                           $input-btn-focus-width;
$input-focus-box-shadow:                      $input-btn-focus-box-shadow;
$input-placeholder-color:                     #98A2B3;
$input-plaintext-color:                       var(--#{$prefix}body-color);
$input-height-border:                         calc(#{$input-border-width} * 2); // stylelint-disable-line function-disallowed-list
$input-height-inner:                          add($input-line-height * 1em, $input-padding-y * 2);
$input-height-inner-half:                     add($input-line-height * .5em, $input-padding-y);
$input-height-inner-quarter:                  add($input-line-height * .25em, $input-padding-y * .5);
$input-height:                                add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false));
$input-height-sm:                             add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false));
$input-height-lg:                             add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false));
$input-transition:                            border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$form-color-width:                            3rem;

$form-check-input-width:                      1em;
$form-check-min-height:                       $font-size-base * $line-height-base;
$form-check-padding-start:                    $form-check-input-width + .5em;
$form-check-margin-bottom:                    .125rem;
$form-check-label-color:                      null;
$form-check-label-cursor:                     null;
$form-check-transition:                       null;
$form-check-input-active-filter:              brightness(90%);
$form-check-input-bg:                         $input-bg;
$form-check-input-border:                     var(--#{$prefix}border-width) solid var(--#{$prefix}border-color);
$form-check-input-border-radius:              .25em;
$form-check-radio-border-radius:              50%;
$form-check-input-focus-border:               $input-focus-border-color;
$form-check-input-focus-box-shadow:           $focus-ring-box-shadow;
$form-check-input-checked-color:              $component-active-color;
$form-check-input-checked-bg-color:           $component-active-bg;
$form-check-input-checked-border-color:       $form-check-input-checked-bg-color;
$form-check-input-checked-bg-image:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>");
$form-check-radio-checked-bg-image:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>");
$form-check-input-indeterminate-color:        $component-active-color;
$form-check-input-indeterminate-bg-color:     $component-active-bg;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color;
$form-check-input-indeterminate-bg-image:     url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");
$form-check-input-disabled-opacity:           .5;
$form-check-label-disabled-opacity:           $form-check-input-disabled-opacity;
$form-check-btn-check-disabled-opacity:       $btn-disabled-opacity;
$form-check-inline-margin-end:                1rem;

$form-switch-color:                           rgba($black, .25);
$form-switch-width:                           2em;
$form-switch-padding-start:                   $form-switch-width + .5em;
$form-switch-bg-image:                        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>");
$form-switch-border-radius:                   $form-switch-width;
$form-switch-transition:                      background-position .15s ease-in-out;
$form-switch-focus-color:                     $input-focus-border-color;
$form-switch-focus-bg-image:                  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>");
$form-switch-checked-color:                   $component-active-color;
$form-switch-checked-bg-image:                url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>");
$form-switch-checked-bg-position:             right center;

$input-group-addon-padding-y:                 $input-padding-y;
$input-group-addon-padding-x:                 $input-padding-x;
$input-group-addon-font-weight:               $input-font-weight;
$input-group-addon-color:                     $input-color;
$input-group-addon-bg:                        var(--#{$prefix}tertiary-bg);
$input-group-addon-border-color:              $input-border-color;

$form-select-padding-y:                       $input-padding-y;
$form-select-padding-x:                       $input-padding-x;
$form-select-font-family:                     $input-font-family;
$form-select-font-size:                       $input-font-size;
$form-select-indicator-padding:               $form-select-padding-x * 3; // Extra padding for background-image
$form-select-font-weight:                     $input-font-weight;
$form-select-line-height:                     $input-line-height;
$form-select-color:                           $input-color;
$form-select-bg:                              $input-bg;
$form-select-disabled-color:                  null;
$form-select-disabled-bg:                     $input-disabled-bg;
$form-select-disabled-border-color:           $input-disabled-border-color;
$form-select-bg-position:                     right $form-select-padding-x center;
$form-select-bg-size:                         16px 12px; // In pixels because image dimensions
$form-select-indicator-color:                 $gray-800;
$form-select-indicator:                       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>");
$form-select-feedback-icon-padding-end:       $form-select-padding-x * 2.5 + $form-select-indicator-padding;
$form-select-feedback-icon-position:          center right $form-select-indicator-padding;
$form-select-feedback-icon-size:              $input-height-inner-half $input-height-inner-half;
$form-select-border-width:                    $input-border-width;
$form-select-border-color:                    $input-border-color;
$form-select-border-radius:                   $input-border-radius;
$form-select-box-shadow:                      var(--#{$prefix}box-shadow-inset);
$form-select-focus-border-color:              $input-focus-border-color;
$form-select-focus-width:                     $input-focus-width;
$form-select-focus-box-shadow:                0 0 0 $form-select-focus-width $input-btn-focus-color;
$form-select-padding-y-sm:                    $input-padding-y-sm;
$form-select-padding-x-sm:                    $input-padding-x-sm;
$form-select-font-size-sm:                    $input-font-size-sm;
$form-select-border-radius-sm:                $input-border-radius-sm;
$form-select-padding-y-lg:                    $input-padding-y-lg;
$form-select-padding-x-lg:                    $input-padding-x-lg;
$form-select-font-size-lg:                    $input-font-size-lg;
$form-select-border-radius-lg:                $input-border-radius-lg;
$form-select-transition:                      $input-transition;

$form-range-track-width:                      100%;
$form-range-track-height:                     .5rem;
$form-range-track-cursor:                     pointer;
$form-range-track-bg:                         var(--#{$prefix}secondary-bg);
$form-range-track-border-radius:              1rem;
$form-range-track-box-shadow:                 var(--#{$prefix}box-shadow-inset);
$form-range-thumb-width:                      1rem;
$form-range-thumb-height:                     $form-range-thumb-width;
$form-range-thumb-bg:                         $component-active-bg;
$form-range-thumb-border:                     0;
$form-range-thumb-border-radius:              1rem;
$form-range-thumb-box-shadow:                 0 .1rem .25rem rgba($black, .1);
$form-range-thumb-focus-box-shadow:           0 0 0 1px $body-bg, $input-focus-box-shadow;
$form-range-thumb-focus-box-shadow-width:     $input-focus-width; // For focus box shadow issue in Edge
$form-range-thumb-active-bg:                  tint-color($component-active-bg, 70%);
$form-range-thumb-disabled-bg:                var(--#{$prefix}secondary-color);
$form-range-thumb-transition:                 background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$form-file-button-color:                      $input-color;
$form-file-button-bg:                         var(--#{$prefix}tertiary-bg);
$form-file-button-hover-bg:                   var(--#{$prefix}secondary-bg);

$form-floating-height:                        add(3.5rem, $input-height-border);
$form-floating-line-height:                   1.25;
$form-floating-padding-x:                     $input-padding-x;
$form-floating-padding-y:                     1rem;
$form-floating-input-padding-t:               1.625rem;
$form-floating-input-padding-b:               .625rem;
$form-floating-label-height:                  1.5em;
$form-floating-label-opacity:                 .65;
$form-floating-label-transform:               scale(.85) translateY(-.5rem) translateX(.15rem);
$form-floating-label-disabled-color:          $gray-600;
$form-floating-transition:                    opacity .1s ease-in-out, transform .1s ease-in-out;

// Form validation

$form-feedback-margin-top:                    $form-text-margin-top;
$form-feedback-font-size:                     $form-text-font-size;
$form-feedback-font-style:                    $form-text-font-style;
$form-feedback-valid-color:                   $success;
$form-feedback-invalid-color:                 $danger;
$form-feedback-icon-valid-color:              $form-feedback-valid-color;
$form-feedback-icon-valid:                    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
$form-feedback-icon-invalid-color:            $form-feedback-invalid-color;
$form-feedback-icon-invalid:                  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");
$form-valid-color:                            $form-feedback-valid-color;
$form-valid-border-color:                     $form-feedback-valid-color;
$form-invalid-color:                          $form-feedback-invalid-color;
$form-invalid-border-color:                   $form-feedback-invalid-color;
$form-validation-states:                      (
                                                  "valid": (
                                                      "color": var(--#{$prefix}form-valid-color),
                                                      "icon": $form-feedback-icon-valid,
                                                      "tooltip-color": #ffffff,
                                                      "tooltip-bg-color": var(--#{$prefix}success),
                                                      "focus-box-shadow": 0 0 $input-btn-focus-blur $input-focus-width rgba(var(--#{$prefix}success-rgb), $input-btn-focus-color-opacity),
                                                      "border-color": var(--#{$prefix}form-valid-border-color),
                                                  ),
                                                  "invalid": (
                                                      "color": var(--#{$prefix}form-invalid-color),
                                                      "icon": $form-feedback-icon-invalid,
                                                      "tooltip-color": #ffffff,
                                                      "tooltip-bg-color": var(--#{$prefix}danger),
                                                      "focus-box-shadow": 0 0 $input-btn-focus-blur $input-focus-width rgba(var(--#{$prefix}danger-rgb), $input-btn-focus-color-opacity),
                                                      "border-color": var(--#{$prefix}form-invalid-border-color),
                                                  )
                                              );

// Z-index master list

$zindex-dropdown:                             1000;
$zindex-sticky:                               1020;
$zindex-fixed:                                1030;
$zindex-offcanvas-backdrop:                   1040;
$zindex-offcanvas:                            1045;
$zindex-modal-backdrop:                       1050;
$zindex-modal:                                1055;
$zindex-popover:                              1070;
$zindex-tooltip:                              1080;
$zindex-toast:                                1090;
$zindex-levels:                               (
                                                  n1: -1,
                                                  0: 0,
                                                  1: 1,
                                                  2: 2,
                                                  3: 3
                                              );

// Navs

$nav-link-padding-y:                          .5rem;
$nav-link-padding-x:                          1rem;
$nav-link-font-size:                          null;
$nav-link-font-weight:                        null;
$nav-link-color:                              var(--#{$prefix}link-color);
$nav-link-hover-color:                        var(--#{$prefix}link-hover-color);
$nav-link-transition:                         color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
$nav-link-disabled-color:                     var(--#{$prefix}secondary-color);
$nav-link-focus-box-shadow:                   $focus-ring-box-shadow;
$nav-tabs-border-color:                       var(--#{$prefix}border-color);
$nav-tabs-border-width:                       var(--#{$prefix}border-width);
$nav-tabs-border-radius:                      var(--#{$prefix}border-radius);
$nav-tabs-link-hover-border-color:            var(--#{$prefix}secondary-bg) var(--#{$prefix}secondary-bg) $nav-tabs-border-color;
$nav-tabs-link-active-color:                  var(--#{$prefix}emphasis-color);
$nav-tabs-link-active-bg:                     var(--#{$prefix}body-bg);
$nav-tabs-link-active-border-color:           var(--#{$prefix}border-color) var(--#{$prefix}border-color) $nav-tabs-link-active-bg;
$nav-pills-border-radius:                     var(--#{$prefix}border-radius);
$nav-pills-link-active-color:                 $component-active-color;
$nav-pills-link-active-bg:                    $component-active-bg;
$nav-underline-gap:                           1rem;
$nav-underline-border-width:                  .125rem;
$nav-underline-link-active-color:             var(--#{$prefix}emphasis-color);

// Navbar

$navbar-padding-y:                            $spacer * .5;
$navbar-padding-x:                            null;
$navbar-nav-link-padding-x:                   .5rem;
$navbar-brand-font-size:                      $font-size-lg;
$nav-link-height:                             $font-size-base * $line-height-base + $nav-link-padding-y * 2;
$navbar-brand-height:                         $navbar-brand-font-size * $line-height-base;
$navbar-brand-padding-y:                      ($nav-link-height - $navbar-brand-height) * .5;
$navbar-brand-margin-end:                     1rem;
$navbar-toggler-padding-y:                    .25rem;
$navbar-toggler-padding-x:                    .75rem;
$navbar-toggler-font-size:                    $font-size-lg;
$navbar-toggler-border-radius:                $btn-border-radius;
$navbar-toggler-focus-width:                  $btn-focus-width;
$navbar-toggler-transition:                   box-shadow .15s ease-in-out;
$navbar-light-color:                          rgba(var(--#{$prefix}emphasis-color-rgb), .65);
$navbar-light-hover-color:                    rgba(var(--#{$prefix}emphasis-color-rgb), .8);
$navbar-light-active-color:                   rgba(var(--#{$prefix}emphasis-color-rgb), 1);
$navbar-light-disabled-color:                 rgba(var(--#{$prefix}emphasis-color-rgb), .3);
$navbar-light-icon-color:                     rgba($body-color, .75);
$navbar-light-toggler-icon-bg:                url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-border-color:           rgba(var(--#{$prefix}emphasis-color-rgb), .15);
$navbar-light-brand-color:                    $navbar-light-active-color;
$navbar-light-brand-hover-color:              $navbar-light-active-color;
$navbar-dark-color:                           rgba($white, .55);
$navbar-dark-hover-color:                     rgba($white, .75);
$navbar-dark-active-color:                    $white;
$navbar-dark-disabled-color:                  rgba($white, .25);
$navbar-dark-icon-color:                      $navbar-dark-color;
$navbar-dark-toggler-icon-bg:                 url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-dark-toggler-border-color:            rgba($white, .1);
$navbar-dark-brand-color:                     $navbar-dark-active-color;
$navbar-dark-brand-hover-color:               $navbar-dark-active-color;

// Dropdowns

$dropdown-min-width:                          10rem;
$dropdown-padding-x:                          0;
$dropdown-padding-y:                          .5rem;
$dropdown-spacer:                             .125rem;
$dropdown-font-size:                          $font-size-base;
$dropdown-color:                              var(--#{$prefix}body-color);
$dropdown-bg:                                 var(--#{$prefix}body-bg);
$dropdown-border-color:                       var(--#{$prefix}border-color-translucent);
$dropdown-border-radius:                      var(--#{$prefix}border-radius);
$dropdown-border-width:                       var(--#{$prefix}border-width);
$dropdown-inner-border-radius:                calc(#{$dropdown-border-radius} - #{$dropdown-border-width}); // stylelint-disable-line function-disallowed-list
$dropdown-divider-bg:                         $dropdown-border-color;
$dropdown-divider-margin-y:                   $spacer * .5;
$dropdown-box-shadow:                         var(--#{$prefix}box-shadow);
$dropdown-link-color:                         var(--#{$prefix}body-color);
$dropdown-link-hover-color:                   $dropdown-link-color;
$dropdown-link-hover-bg:                      var(--#{$prefix}tertiary-bg);
$dropdown-link-active-color:                  $component-active-color;
$dropdown-link-active-bg:                     $component-active-bg;
$dropdown-link-disabled-color:                var(--#{$prefix}tertiary-color);
$dropdown-item-padding-y:                     $spacer * .25;
$dropdown-item-padding-x:                     $spacer;
$dropdown-header-color:                       $gray-600;
$dropdown-header-padding-x:                   $dropdown-item-padding-x;
$dropdown-header-padding-y:                   $dropdown-padding-y;
$dropdown-dark-color:                         $gray-300;
$dropdown-dark-bg:                            $gray-800;
$dropdown-dark-border-color:                  $dropdown-border-color;
$dropdown-dark-divider-bg:                    $dropdown-divider-bg;
$dropdown-dark-box-shadow:                    null;
$dropdown-dark-link-color:                    $dropdown-dark-color;
$dropdown-dark-link-hover-color:              $white;
$dropdown-dark-link-hover-bg:                 rgba($white, .15);
$dropdown-dark-link-active-color:             $dropdown-link-active-color;
$dropdown-dark-link-active-bg:                $dropdown-link-active-bg;
$dropdown-dark-link-disabled-color:           $gray-500;
$dropdown-dark-header-color:                  $gray-500;

// Pagination

$pagination-padding-y:                        .375rem;
$pagination-padding-x:                        .75rem;
$pagination-padding-y-sm:                     .25rem;
$pagination-padding-x-sm:                     .5rem;
$pagination-padding-y-lg:                     .75rem;
$pagination-padding-x-lg:                     1.5rem;
$pagination-font-size:                        $font-size-base;
$pagination-color:                            var(--#{$prefix}link-color);
$pagination-bg:                               var(--#{$prefix}body-bg);
$pagination-border-radius:                    var(--#{$prefix}border-radius);
$pagination-border-width:                     var(--#{$prefix}border-width);
$pagination-margin-start:                     calc(#{$pagination-border-width} * -1); // stylelint-disable-line function-disallowed-list
$pagination-border-color:                     var(--#{$prefix}border-color);
$pagination-focus-color:                      var(--#{$prefix}link-hover-color);
$pagination-focus-bg:                         var(--#{$prefix}secondary-bg);
$pagination-focus-box-shadow:                 $focus-ring-box-shadow;
$pagination-focus-outline:                    0;
$pagination-hover-color:                      var(--#{$prefix}link-hover-color);
$pagination-hover-bg:                         var(--#{$prefix}tertiary-bg);
$pagination-hover-border-color:               var(--#{$prefix}border-color); // Todo in v6: remove this?
$pagination-active-color:                     $component-active-color;
$pagination-active-bg:                        $component-active-bg;
$pagination-active-border-color:              $component-active-bg;
$pagination-disabled-color:                   var(--#{$prefix}secondary-color);
$pagination-disabled-bg:                      var(--#{$prefix}secondary-bg);
$pagination-disabled-border-color:            var(--#{$prefix}border-color);
$pagination-transition:                       color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
$pagination-border-radius-sm:                 var(--#{$prefix}border-radius-sm);
$pagination-border-radius-lg:                 var(--#{$prefix}border-radius-lg);

// Placeholders

$placeholder-opacity-max:                     .5;
$placeholder-opacity-min:                     .2;

// Cards

//$card-spacer-y:                               $spacer;
//$card-spacer-x:                               $spacer;
//$card-title-spacer-y:                         $spacer * .5;
//$card-title-color:                            null;
//$card-subtitle-color:                         null;
//$card-border-width:                           1px; // * var(--#{$prefix}border-width);
//$card-border-color:                           #e3e3e3; // * var(--#{$prefix}border-color-translucent);
//$card-border-radius:                          8px; // * var(--#{$prefix}border-radius);
//$card-box-shadow:                             null;
//$card-inner-border-radius:                    subtract($card-border-radius, $card-border-width);
//$card-cap-padding-y:                          $card-spacer-y * .5;
//$card-cap-padding-x:                          $card-spacer-x;
//$card-cap-bg:                                 rgba(var(--#{$prefix}body-color-rgb), .03);
//$card-cap-color:                              null;
//$card-height:                                 null;
//$card-color:                                  null;
//$card-bg:                                     white; // * var(--#{$prefix}body-bg);
//$card-img-overlay-padding:                    $spacer;
//$card-group-margin:                           $grid-gutter-width * .5;

// Accordion

$accordion-padding-y:                         1rem;
$accordion-padding-x:                         1.25rem;
$accordion-color:                             var(--#{$prefix}body-color);
$accordion-bg:                                var(--#{$prefix}body-bg);
$accordion-border-width:                      var(--#{$prefix}border-width);
$accordion-border-color:                      var(--#{$prefix}border-color);
$accordion-border-radius:                     var(--#{$prefix}border-radius);
$accordion-inner-border-radius:               subtract($accordion-border-radius, $accordion-border-width);
$accordion-body-padding-y:                    $accordion-padding-y;
$accordion-body-padding-x:                    $accordion-padding-x;
$accordion-button-padding-y:                  $accordion-padding-y;
$accordion-button-padding-x:                  $accordion-padding-x;
$accordion-button-color:                      var(--#{$prefix}body-color);
$accordion-button-bg:                         var(--#{$prefix}accordion-bg);
$accordion-transition:                        $btn-transition, border-radius .15s ease;
$accordion-button-active-bg:                  var(--#{$prefix}primary-bg-subtle);
$accordion-button-active-color:               var(--#{$prefix}primary-text-emphasis);
$accordion-button-focus-border-color:         $input-focus-border-color;
$accordion-button-focus-box-shadow:           $btn-focus-box-shadow;
$accordion-icon-width:                        1.25rem;
$accordion-icon-color:                        $body-color;
$accordion-icon-active-color:                 $primary-text-emphasis;
$accordion-icon-transition:                   transform .2s ease-in-out;
$accordion-icon-transform:                    rotate(-180deg);
$accordion-button-icon:                       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon:                url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");

// Tooltips

$tooltip-font-size:                           $font-size-sm;
$tooltip-max-width:                           200px;
$tooltip-color:                               var(--#{$prefix}body-bg);
$tooltip-bg:                                  var(--#{$prefix}emphasis-color);
$tooltip-border-radius:                       var(--#{$prefix}border-radius);
$tooltip-opacity:                             .9;
$tooltip-padding-y:                           $spacer * .25;
$tooltip-padding-x:                           $spacer * .5;
$tooltip-margin:                              null;
$tooltip-arrow-width:                         .8rem;
$tooltip-arrow-height:                        .4rem;
$form-feedback-tooltip-padding-y:             $tooltip-padding-y;
$form-feedback-tooltip-padding-x:             $tooltip-padding-x;
$form-feedback-tooltip-font-size:             $tooltip-font-size;
$form-feedback-tooltip-line-height:           null;
$form-feedback-tooltip-opacity:               $tooltip-opacity;
$form-feedback-tooltip-border-radius:         $tooltip-border-radius;

// Popovers

$popover-font-size:                           $font-size-sm;
$popover-bg:                                  var(--#{$prefix}body-bg);
$popover-max-width:                           276px;
$popover-border-width:                        var(--#{$prefix}border-width);
$popover-border-color:                        var(--#{$prefix}border-color-translucent);
$popover-border-radius:                       var(--#{$prefix}border-radius-lg);
$popover-inner-border-radius:                 calc(#{$popover-border-radius} - #{$popover-border-width}); // stylelint-disable-line function-disallowed-list
$popover-box-shadow:                          var(--#{$prefix}box-shadow);
$popover-header-font-size:                    $font-size-base;
$popover-header-bg:                           var(--#{$prefix}secondary-bg);
$popover-header-color:                        $headings-color;
$popover-header-padding-y:                    .5rem;
$popover-header-padding-x:                    $spacer;
$popover-body-color:                          var(--#{$prefix}body-color);
$popover-body-padding-y:                      $spacer;
$popover-body-padding-x:                      $spacer;
$popover-arrow-width:                         1rem;
$popover-arrow-height:                        .5rem;

// Toasts

$toast-max-width:                             350px;
$toast-padding-x:                             .75rem;
$toast-padding-y:                             .5rem;
$toast-font-size:                             .875rem;
$toast-color:                                 null;
$toast-background-color:                      rgba(var(--#{$prefix}body-bg-rgb), .85);
$toast-border-width:                          var(--#{$prefix}border-width);
$toast-border-color:                          var(--#{$prefix}border-color-translucent);
$toast-border-radius:                         var(--#{$prefix}border-radius);
$toast-box-shadow:                            var(--#{$prefix}box-shadow);
$toast-spacing:                               $container-padding-x;
$toast-header-color:                          var(--#{$prefix}secondary-color);
$toast-header-background-color:               rgba(var(--#{$prefix}body-bg-rgb), .85);
$toast-header-border-color:                   $toast-border-color;

// Badges

$badge-font-size:                             .75em;
$badge-font-weight:                           $font-weight-bold;
$badge-color:                                 $white;
$badge-padding-y:                             .35em;
$badge-padding-x:                             .65em;
$badge-border-radius:                         var(--#{$prefix}border-radius);

// Modals

$modal-inner-padding:                         $spacer;
$modal-footer-margin-between:                 .5rem;
$modal-dialog-margin:                         .5rem;
$modal-dialog-margin-y-sm-up:                 1.75rem;
$modal-title-line-height:                     $line-height-base;
$modal-content-color:                         $white;
$modal-content-bg:                            #11141a; // * var(--#{$prefix}body-bg);
$modal-content-border-color:                  var(--#{$prefix}border-color-translucent);
$modal-content-border-width:                  0; // * var(--#{$prefix}border-width);
$modal-content-border-radius:                 var(--#{$prefix}border-radius-lg);
$modal-content-inner-border-radius:           subtract($modal-content-border-radius, $modal-content-border-width);
$modal-content-box-shadow-xs:                 var(--#{$prefix}box-shadow-sm);
$modal-content-box-shadow-sm-up:              var(--#{$prefix}box-shadow);
$modal-backdrop-bg:                           $black;
$modal-backdrop-opacity:                      .5;
$modal-header-border-color:                   var(--#{$prefix}border-color);
$modal-header-border-width:                   0; // * $modal-content-border-width;
$modal-header-padding-y:                      $modal-inner-padding;
$modal-header-padding-x:                      $modal-inner-padding;
$modal-header-padding:                        $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility
$modal-footer-bg:                             null;
$modal-footer-border-color:                   $modal-header-border-color;
$modal-footer-border-width:                   0; // * $modal-header-border-width;
$modal-sm:                                    300px;
$modal-md:                                    500px;
$modal-lg:                                    800px;
$modal-xl:                                    1140px;
$modal-fade-transform:                        translate(0, -50px);
$modal-show-transform:                        none;
$modal-transition:                            transform .3s ease-out;
$modal-scale-transform:                       scale(1.02);

// Alerts

$alert-padding-y:                             $spacer;
$alert-padding-x:                             $spacer;
$alert-margin-bottom:                         1rem;
$alert-border-radius:                         var(--#{$prefix}border-radius);
$alert-link-font-weight:                      $font-weight-bold;
$alert-border-width:                          var(--#{$prefix}border-width);
$alert-dismissible-padding-r:                 $alert-padding-x * 3; // 3x covers width of x plus default padding on either side

// Progress bars

$progress-height:                             1rem;
$progress-font-size:                          $font-size-base * .75;
$progress-bg:                                 var(--#{$prefix}secondary-bg);
$progress-border-radius:                      var(--#{$prefix}border-radius);
$progress-box-shadow:                         var(--#{$prefix}box-shadow-inset);
$progress-bar-color:                          $white;
$progress-bar-bg:                             $primary;
$progress-bar-animation-timing:               1s linear infinite;
$progress-bar-transition:                     width .6s ease;

// List group

$list-group-color:                            var(--#{$prefix}body-color);
$list-group-bg:                               var(--#{$prefix}body-bg);
$list-group-border-color:                     var(--#{$prefix}border-color);
$list-group-border-width:                     var(--#{$prefix}border-width);
$list-group-border-radius:                    var(--#{$prefix}border-radius);
$list-group-item-padding-y:                   $spacer * .5;
$list-group-item-padding-x:                   $spacer;
$list-group-hover-bg:                         var(--#{$prefix}tertiary-bg);
$list-group-active-color:                     $component-active-color;
$list-group-active-bg:                        $component-active-bg;
$list-group-active-border-color:              $list-group-active-bg;
$list-group-disabled-color:                   var(--#{$prefix}secondary-color);
$list-group-disabled-bg:                      $list-group-bg;
$list-group-action-color:                     var(--#{$prefix}secondary-color);
$list-group-action-hover-color:               var(--#{$prefix}emphasis-color);
$list-group-action-active-color:              var(--#{$prefix}body-color);
$list-group-action-active-bg:                 var(--#{$prefix}secondary-bg);

// Image thumbnails

$thumbnail-padding:                           .25rem;
$thumbnail-bg:                                var(--#{$prefix}body-bg);
$thumbnail-border-width:                      var(--#{$prefix}border-width);
$thumbnail-border-color:                      var(--#{$prefix}border-color);
$thumbnail-border-radius:                     var(--#{$prefix}border-radius);
$thumbnail-box-shadow:                        var(--#{$prefix}box-shadow-sm);

// Figures

$figure-caption-font-size:                    $small-font-size;
$figure-caption-color:                        var(--#{$prefix}secondary-color);

// Breadcrumbs

$breadcrumb-font-size:                        null;
$breadcrumb-padding-y:                        0;
$breadcrumb-padding-x:                        0;
$breadcrumb-item-padding-x:                   .5rem;
$breadcrumb-margin-bottom:                    1rem;
$breadcrumb-bg:                               null;
$breadcrumb-divider-color:                    var(--#{$prefix}secondary-color);
$breadcrumb-active-color:                     var(--#{$prefix}secondary-color);
$breadcrumb-divider:                          quote("/");
$breadcrumb-divider-flipped:                  $breadcrumb-divider;
$breadcrumb-border-radius:                    null;

// Carousel

$carousel-control-color:                      $white;
$carousel-control-width:                      15%;
$carousel-control-opacity:                    .5;
$carousel-control-hover-opacity:              .9;
$carousel-control-transition:                 opacity .15s ease;
$carousel-indicator-width:                    30px;
$carousel-indicator-height:                   3px;
$carousel-indicator-hit-area-height:          10px;
$carousel-indicator-spacer:                   3px;
$carousel-indicator-opacity:                  .5;
$carousel-indicator-active-bg:                $white;
$carousel-indicator-active-opacity:           1;
$carousel-indicator-transition:               opacity .6s ease;
$carousel-caption-width:                      70%;
$carousel-caption-color:                      $white;
$carousel-caption-padding-y:                  1.25rem;
$carousel-caption-spacer:                     1.25rem;
$carousel-control-icon-width:                 2rem;
$carousel-control-prev-icon-bg:               url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
$carousel-control-next-icon-bg:               url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
$carousel-transition-duration:                .6s;
$carousel-transition:                         transform $carousel-transition-duration ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)
$carousel-dark-indicator-active-bg:           $black;
$carousel-dark-caption-color:                 $black;
$carousel-dark-control-icon-filter:           invert(1) grayscale(100);

// Spinners

$spinner-width:                               2rem;
$spinner-height:                              $spinner-width;
$spinner-vertical-align:                      -.125em;
$spinner-border-width:                        .25em;
$spinner-animation-speed:                     .75s;
$spinner-width-sm:                            1rem;
$spinner-height-sm:                           $spinner-width-sm;
$spinner-border-width-sm:                     .2em;

// Close

$btn-close-width:                             1em;
$btn-close-height:                            $btn-close-width;
$btn-close-padding-x:                         .25em;
$btn-close-padding-y:                         $btn-close-padding-x;
$btn-close-color:                             $black;
$btn-close-bg:                                url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>");
$btn-close-focus-shadow:                      $focus-ring-box-shadow;
$btn-close-opacity:                           .5;
$btn-close-hover-opacity:                     .75;
$btn-close-focus-opacity:                     1;
$btn-close-disabled-opacity:                  .25;
$btn-close-white-filter:                      invert(1) grayscale(100%) brightness(200%);

// Offcanvas

$offcanvas-padding-y:                         $modal-inner-padding;
$offcanvas-padding-x:                         $modal-inner-padding;
$offcanvas-horizontal-width:                  400px;
$offcanvas-vertical-height:                   30vh;
$offcanvas-transition-duration:               .3s;
$offcanvas-border-color:                      $modal-content-border-color;
$offcanvas-border-width:                      $modal-content-border-width;
$offcanvas-title-line-height:                 $modal-title-line-height;
$offcanvas-bg-color:                          var(--#{$prefix}body-bg);
$offcanvas-color:                             var(--#{$prefix}body-color);
$offcanvas-box-shadow:                        $modal-content-box-shadow-xs;
$offcanvas-backdrop-bg:                       $modal-backdrop-bg;
$offcanvas-backdrop-opacity:                  $modal-backdrop-opacity;

// Code

$code-font-size:                              $small-font-size;
$code-color:                                  $pink;
$kbd-padding-y:                               .1875rem;
$kbd-padding-x:                               .375rem;
$kbd-font-size:                               $code-font-size;
$kbd-color:                                   var(--#{$prefix}body-bg);
$kbd-bg:                                      var(--#{$prefix}body-color);
$pre-color:                                   null;
