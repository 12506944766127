//**********************************************
// Typography
//**********************************************

// Fonts
//@import "https://fonts.googleapis.com/css2?family=Inter&display=swap";
@font-face {
    font-family: "Inter";
    font-style:  normal;
    font-weight: 400;
    src:         local("Inter"),
                 url("#{$font-url}/Inter-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Inter";
    font-style:  normal;
    font-weight: 500;
    src:         local("Inter Medium"),
                 url("#{$font-url}/Inter-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Inter";
    font-style:  normal;
    font-weight: 600;
    src:         local("Inter SemiBold"),
                 url("#{$font-url}/Inter-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Inter";
    font-style:  normal;
    font-weight: 700;
    src:         local("Inter Bold"),
                 url("#{$font-url}/Inter-Bold.ttf") format("truetype");
}

//**********************************************
// Heading
//**********************************************

h1, h2, h3, h4, h5, h6 {
    .app-container > & {
        @include container(&);
    }
}

h1 {

}

h2 {
    font-size:   1.375rem;
    font-weight: 700;
    line-height: 1.6875rem;
    letter-spacing: -0.01em;
    color:       #344054;
}

h3 {

}

h4 {

}

h5 {

}

h6 {

}