//**********************************************
// Theme Light
//**********************************************
:root,
[data-theme="light"] {
    color-scheme:          light;
    --app-header-bg-color: white;
    --app-footer-bg-color: white;
    --app-main-bg-color: #f9f9f9;
}

//**********************************************
// Theme Dark
//**********************************************
[data-theme="dark"] {
    color-scheme:          dark;
    --app-header-bg-color: var(--bs-primary-rgb);
    --app-footer-bg-color: var(--bs-primary-rgb);
    --app-main-bg-color:   var(--bs-body-bg);
}