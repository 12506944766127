@use "sass:math";

// Bootstrap
@import "bootstrap";

// Application Modules
@import "mixins";
@import "theme";
@import "typography";
@import "layot";
@import "form";

// Prevent iPhone zoom
html, body {
    font-size: 16px;
}
.form-control {
    font-size: 16px;
}

* {
    outline: none;
}

::placeholder {
    color: #98A2B3;
    opacity: 1;
}

//**********************************************
// App Footer
//**********************************************

#app-footer {
    overflow-x:       hidden;
    background-color: var(--app-footer-bg-color);
    box-shadow:       0 -2px 2px 0 rgba(0, 0, 0, 0.1);

    > .app-container {
        display:               grid;
        align-content:         center;
        align-items:           center;
        justify-content:       center;
        min-height:            $footer-height;
        grid-template-columns: 1fr;
        justify-items:         stretch;
    }
}

//**********************************************
// App Content
//**********************************************

.app-content {
    @include container(&);

    > :first-child:not(.app-card) {
        margin-bottom: 1rem;
        padding-right: 1rem;
        padding-left:  1rem;
    }

    > .app-card {
        @extend .mb-2;
    }

    > .navbar {
        background-color: white;
        box-shadow:       0 -2px 2px 0 rgba(0, 0, 0, .1);
        padding-top:      0;
        padding-bottom:   0;

        @extend .fixed-bottom, .navbar-expand;

        &, > .app-container {
            min-height: $footer-height;
        }

        > .app-container {
            display:               grid;
            align-content:         center;
            align-items:           center;
            justify-content:       center;
            grid-template-columns: 1fr;
            justify-items:         stretch;
        }

        .btn {
            font-size:    1rem;
            font-weight:  500;
            line-height:  1;
            height:       3rem;
            max-height:   3rem;
            border-width: 0;

            @extend .btn-lg;
            @include container(&, 1rem);

            &, &.btn-primary {
                color:            white;
                background-color: #2f56ff;
            }

            &.btn-secondary {
                color:            #2f56ff;
                background-color: rgba(47, 86, 255, .1);
            }
        }
    }

    > :last-child {
        @extend .mb-0;
    }
}

//**********************************************
// App Card
//**********************************************

.app-card {
    padding:          1rem;
    border:           1px solid #e3e3e3;
    border-radius:    .625rem;
    background-color: white;
    box-shadow:       0 3px 2px 0 rgba(0, 0, 0, 0.04),
                      0 3px 16px 0 rgba(0, 0, 0, 0.06);

    .card-text {
        font-size:   1rem;
        font-weight: 400;
        line-height: 1.5rem;
        color:       #344054;

        > a.d-block {
            @extend .mt-2;
        }
    }

    > :only-child,
    > :not(:only-child):first-child {
        @extend .mt-0;
    }

    &:last-of-type,
    > :only-child,
    > :not(:only-child):last-child {
        @extend .mb-0;
    }

    .app-form > & > div:not(:last-of-type) {
        @extend .mb-3;
    }

    .alert {
        font-size:     .75rem;
        font-weight:   400;
        line-height:   1rem;
        color:         #667085;
        background:    linear-gradient(0deg, rgba(47, 86, 255, .05), rgba(47, 86, 255, .05)),
                       linear-gradient(0deg, rgba(47, 86, 255, .02), rgba(47, 86, 255, .02));
        display:       flex;
        align-items:   flex-start;
        border-radius: 8px;
        border:        1px solid rgba(47, 86, 255, .05);
        padding:       12px;

        > img:first-of-type {
            margin-top:   2px;
            margin-right: .5rem;
        }
    }
}

//**********************************************
// App Pane
//**********************************************

.app-pane {
    padding:          1rem;
    background-color: transparent;

    > :only-child,
    > :not(:only-child):first-child {
        @extend .mt-0;
    }

    &:last-of-type,
    > :only-child,
    > :not(:only-child):last-child {
        @extend .mb-0;
    }

    .app-form > & > div:not(:last-of-type) {
        @extend .mb-3;
    }
}

//**********************************************
// Modal
//**********************************************

.modal {
    &-content {
        position: relative;
    }

    &-body {
        padding: 0;
    }

    &-map {
        .modal-header,
        .modal-footer {
            flex-wrap: nowrap;
        }

        .modal-header {
            flex-direction:  row;
            justify-content: center;
            align-items:     center;
            height:          $header-height;
            position:        relative;

            > .btn-close {
                position:  absolute;
                left:      0;
                transform: translate(40%, 0);
            }
        }

        .modal-body {
            display:         flex;
            flex-direction:  column;
            flex-wrap:       nowrap;
            justify-content: center;
        }
    }

    &-map {
        .modal-header {
            background-color: white;
            color:            #344054;
        }

        .modal-footer {
            position: absolute;
            left:     0;
            right:    0;
            bottom:   0;
        }
    }

    &-form {
        .modal-header {
            display:         flex;
            flex-direction:  row;
            flex-wrap:       nowrap;
            justify-content: center;
            align-items:     center;
            position:        relative;

            .app-container {
                text-align: center;
            }

            .btn-close {
                position: absolute;
                right:    1rem;
            }

            .modal-title {
                font-size:      1.375rem;
                font-weight:    700;
                line-height:    1.6875rem;
                letter-spacing: -0.01em;
                color:          #344054;
            }
        }

        .app-form {
            margin-top: 2.5rem;
            margin-bottom: 6.25rem;
        }

        .navbar {
            background-color: white;
            box-shadow: 0 -2px 2px #0000001a;
            padding: 0;
            position: fixed;
            right: 0;
            bottom: 0;
            left: 0;

            > .app-container {
                display: grid;
                align-content: center;
                align-items: center;
                justify-content: center;
                grid-template-columns: 1fr;
                justify-items: stretch;
                min-height: $footer-height;
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        .btn-secondary {
            color:            #2f56ff;
            background-color: #2f56ff1a;
            //margin-left:      1rem;
            margin-right:     1rem;
            height: 3rem;
            max-height: 3rem;
            border-width: 0;
        }
    }

    &-text {
        .modal-content {
            background-color: white;
            color: #344054;
        }

        .btn-secondary,
        .btn-secondary:active {
            width: 100%;
            color: #2f56ff;
            background-color: #2f56ff1a;
            height: 3rem;
            max-height: 3rem;
            border-width: 0;
        }
    }
}

//**********************************************
// Button
//**********************************************

.btn {
    font-size:       1rem;
    font-weight:     500;
    line-height:     1;
    display:         inline-flex;
    flex-direction:  row;
    flex-wrap:       nowrap;
    justify-content: center;
    align-items:     center;
    border-radius:   .5rem;
    position:        relative;
    min-width:       2.5rem;
    min-height:      2.5rem;
    padding:         0 .75rem;

    &-link {
        padding-right: 0;
        padding-left:  0;
    }

    &-light {
        background-color: transparent;
        border-color:     transparent;
        box-shadow:       none;
    }

    &-link,
    &-light {
        color: #2f56ff;
    }

    > .badge {
        $badge-height:  1rem;

        background:     rgba(47, 86, 255, .13);
        color:          #344054;
        font-size:      .625rem;
        font-weight:    500;
        line-height:    1;
        letter-spacing: .03125rem;
        position:       absolute;
        top:            0;
        left:           100%;
        transform:      translate(-100%, -10%);
        min-width:      $badge-height;
        height:         $badge-height;
        border-radius:  math.div($badge-height, 2);
        padding-left:   .25rem;
        padding-right:  .25rem;
    }
}

//**********************************************
// Location
//**********************************************

.map {
    &-thumb {
        @extend .position-relative;
    }

    &-preview {
        @extend .border, .rounded, .img-fluid, .w-100;
    }

    &-overlay {
        top:   calc(50% - 18px);
        right: calc(50% - 15px);

        @extend .position-absolute;
    }

    &-backdrop {
        @extend .img-wrapper;

        height: 100%;
    }
}

//**********************************************
// Gallery
//**********************************************

.gallery {
    &-thumb {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 -.25rem .5rem;

        > * {
            width: 33.3333%;
            padding: 0 .25rem .5rem;

            > img {
                border-radius: .375rem;
                max-width: 100%;
                height: auto;
            }
        }
    }
}

//**********************************************
// Image
//**********************************************

.img-wrapper {
    background:      no-repeat center center;
    background-size: cover;
    display:         block;
    max-width:       100%;
    width:           100%;
}

.fs-modal {
    background-color: var(--app-main-bg-color);

    .modal-title,
    h3,
    .accordion-item {
        color: black;
    }
}

.toast-container {
    position: fixed;
    padding-bottom: 6rem;

    .toast-header {
        justify-content: space-between;
    }
}

// Override bootstrap
body {
    min-height: 100vh;
    height: 100%;
    background-color: #f9f9f9;
}

.modal-footer {
    justify-content: center;
    padding-right: calc(100vw - 100%) !important;
}