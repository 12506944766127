//**********************************************
// App List
//**********************************************

.app-list {
    display:        flex;
    flex-direction: column;
    flex-wrap:      nowrap;
    justify-content: space-around;
    align-items:    stretch;

    &.app-card {
        padding-bottom: .5rem;
    }

    > .list-item {
        flex-direction: row;
        flex-wrap:   nowrap;
        align-items: center;

        @extend .row, .mx-0;

        // 1 from 2 columns
        > :first-child:nth-last-child(2):not(.head):not(.body) {
            font-size:   .875rem;
            font-weight: 500;
            line-height: 1.25rem;
            color:       #667085;

            @extend .ps-0, .py-2, .col-7;
        }

        // 2 from 2 columns
        > :last-child:nth-child(2):not(.foot):not(.body) {
            font-size:   1rem;
            font-weight: 500;
            line-height: 1.5rem;
            color:       #344054;

            @extend .pe-0, .py-2, .col-5;
        }

        // 4 columns
        > :first-child:nth-last-child(4),
        > :nth-child(2):nth-last-child(3),
        > :nth-child(3):nth-last-child(2),
        > :last-child:nth-child(4) {
            @extend .px-0, .col-3;
        }

        > .head {
            @extend .ps-0, .col-auto;
        }

        > .body {
            flex-grow: 1;
            @extend .px-0, .col-auto;
        }

        > .foot {
            @extend .pe-0, .col-auto;
        }
    }
}
