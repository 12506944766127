@use "sass:selector";

// Container
@mixin container($child, $gap: .5rem) {
    @at-root #{selector.unify(&, $child)} {
        .app-container > & {
            margin-left:  $gap;
            margin-right: $gap;
        }
    }
}
